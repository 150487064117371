<template>
  <div>
    <ClientOnly>
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline" size="icon">
            <Sun v-if="isDarkTheme" class="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90" />
            <Moon v-else class="h-[1.2rem] w-[1.2rem] rotate-90 transition-all dark:rotate-0 dark:scale-100" />
          <span class="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem @click="setTheme('light')">
          Light
        </DropdownMenuItem>
        <DropdownMenuItem @click="setTheme('dark')">
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem @click="setTheme('system')">
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
    </ClientOnly>   
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { Sun, Moon } from 'lucide-vue-next'
import { useTheme } from '@/composables/useTheme'

const { theme, setTheme } = useTheme()

const isDarkTheme = computed(() => {
  return theme.value === 'dark' || (theme.value === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches)
})

onMounted(() => {
  const savedTheme = localStorage.getItem('theme') || 'system'
  setTheme(savedTheme)
})
</script>