<!-- File: layouts/default.vue or app.vue -->
<template>
  <div class="min-h-screen bg-background text-foreground">
    <TopBar />
    <main class="mx-auto px-4 py-6  mx-auto px-4 sm:px-6 lg:px-8">
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script setup>
import TopBar from '~/components/TopBar.vue'
import Footer from '@/components/Footer.vue'
import { useTheme } from '@/composables/useTheme'

// Initialize theme
useTheme()
</script>