<!-- File: components/TopBar.vue -->
<template>
  <header class="w-full bg-background">
    <div class="fixed w-full px-4 py-2 flex justify-between items-center z-50">
      <div class="flex items-center space-x-4">
        <NuxtLink to="/" class="font-semibold">
        <h1 class="text-xl font-bold text-black dark:text-white difference">BRX</h1>
        </NuxtLink>
        <NuxtLink to="/projects" class="font-semibold">
          <Button variant="outline" class="text-muted-foreground">Projects</Button>
        </NuxtLink>
      </div>
      <div class="flex items-center space-x-4">
        <Button 
          variant="default" 
          class="bg-orange-600 hover:bg-orange-700 text-white"
          @click="openCreateProjectPopup"
        >
        <CirclePlus class="mr-2 " />
          Create Project
        </Button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { inject } from 'vue'
import { Button } from '@/components/ui/button'
import ModeToggle from '~/components/ModeToggle.vue'
import { ArrowLeft, Code2, Edit, X, RefreshCw ,  CirclePlus} from 'lucide-vue-next'

const openCreateProjectPopup = inject('openCreateProjectPopup')
</script>