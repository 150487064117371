<template>
  <div class="fixed bottom-4 left-4 z-50">
    <div
      class="w-10 h-10 flex items-center justify-center cursor-pointer hover:shadow-xl transition-all duration-300"
      @click="toggleExpanded"
    >
      <div
        class="w-10 h-10 rounded-full flex items-center justify-center overflow-hidden"
      >
        <img
          v-if="user && user.photoURL"
          :src="user.photoURL"
          alt="User profile"
          class="w-full h-full object-cover"
        />
        <UserCircle v-else class="w-8 h-8 text-muted-foreground" />
      </div>
    </div>
    <transition name="fade">
      <Card v-if="expanded" class="absolute bottom-12 left-0 w-64" ref="cardRef">
        <CardHeader>
          <CardTitle class="text-lg flex items-center">
            <div class="w-8 h-8 rounded-full flex items-center justify-center mr-2 overflow-hidden">
              <img
                v-if="user && user.photoURL"
                :src="user.photoURL"
                alt="User profile"
                class="w-full h-full object-cover"
              />
              <UserCircle v-else class="w-6 h-6 text-muted-foreground" />
            </div>
            Quick Actions
            <ModeToggle class="pl-2"/>
          </CardTitle>
          <CardDescription v-if="user">
            {{ user.displayName || user.email }}
          </CardDescription>
          <CardDescription v-else>
            Not logged in
          </CardDescription>
        </CardHeader>
        <CardContent class="space-y-2">
          <Button @click="goToBrxAI" class="w-full" variant="outline">
            <ExternalLink class="mr-2 h-4 w-4" />
            Go to BRX.AI
          </Button>
          <Button v-if="user" @click="logout" class="w-full" variant="destructive">
            <LogOut class="mr-2 h-4 w-4" />
            Logout
          </Button>
          <Button v-else @click="login" class="w-full" variant="default">
            <LogIn class="mr-2 h-4 w-4" />
            Login
          </Button>
          <p v-if="error" class="text-red-500 text-sm">{{ error.message }}</p>
        </CardContent>
      </Card>
    </transition>
  </div>
</template>

<script setup lang="ts">
import ModeToggle from '~/components/ModeToggle.vue'
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { ExternalLink, LogOut, LogIn, UserCircle } from 'lucide-vue-next'
import { useAuth } from '@/composables/useAuth'

const { user, error, logout, login } = useAuth()

const expanded = ref(false)
const cardRef = ref<HTMLElement | null>(null)

const toggleExpanded = () => {
  expanded.value = !expanded.value
  if (expanded.value) {
    nextTick(() => {
      document.addEventListener('click', handleClickOutside)
    })
  } else {
    document.removeEventListener('click', handleClickOutside)
  }
}

const goToBrxAI = () => {
  window.open('https://brx.ai', '_blank')
}

const handleClickOutside = (event: MouseEvent) => {
  if (cardRef.value instanceof HTMLElement && !cardRef.value.contains(event.target as Node) && !event.defaultPrevented) {
    expanded.value = false
    document.removeEventListener('click', handleClickOutside)
  }
}

onMounted(() => {
  // If needed, you can add any mounting logic here
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>